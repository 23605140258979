import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import HubDashCell from 'src/components/HubDash/HubDashCell'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'

const HubDashPage = () => {
  const [hubDashEnabled] = useLiveFeature(FLAG_FEATURES.HUBDASH)

  if (hubDashEnabled) {
    return (
      <>
        <Metadata title="HubDash" description="HubDash" />

        <HubDashCell />
      </>
    )
  }
}

export default HubDashPage
